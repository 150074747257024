import React, { useState, useEffect } from 'react';
import { Phone, Activity, DollarSign } from 'lucide-react';
import { patientFlowAPIUnordered as api, trackEvent, fetchBlogPosts } from '../../../services/api';
import SharedBottomContent from '../../../components/SharedBottomContent';

// Custom Alert component
const Alert = ({ children, variant = 'default' }) => {
  const bgColor = variant === 'destructive' ? 'bg-red-100' : 'bg-blue-100';
  const textColor = variant === 'destructive' ? 'text-red-800' : 'text-blue-800';
  
  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-md mb-4`}>
      {children}
    </div>
  );
};

// Add this helper function at the top of the file
const getActiveInvestigations = () => {
  const now = new Date();
  const daysSeed = Math.floor(now.getTime() / (24 * 60 * 60 * 1000)); // Changes daily
  const hoursSeed = Math.floor(now.getTime() / (60 * 60 * 1000)); // Changes hourly
  
  // Base number between 1100-1300
  const baseNumber = 1200 + (((daysSeed * 7919) % 201) - 100);
  
  // Hourly variation of ±5
  const hourlyVariation = ((hoursSeed * 6007) % 11) - 5;
  
  // Ensure final number is between 1000-1400
  const result = Math.min(1400, Math.max(1000, baseNumber + hourlyVariation));
  
  return Math.floor(result);
};

const Step2ContactInfo = ({ existingCaseId, onComplete }) => {
  const [formData, setFormData] = useState({ 
    name: '', 
    email: '',
    phone: '' 
  });
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [activeInvestigations] = useState(getActiveInvestigations());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const posts = await fetchBlogPosts();
        setBlogPosts(posts.slice(0, 3));
      } catch (error) {
        console.error('Error loading blog posts:', error);
      }
    };
    loadPosts();
  }, []);

  const validatePhone = (phone) => {
    const phoneRegex = /^\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'phone') {
      // Remove all non-digits
      const digitsOnly = value.replace(/\D/g, '');
      // Format as (XXX) XXX-XXXX if we have enough digits
      let formattedValue = value;
      if (digitsOnly.length >= 10) {
        formattedValue = `(${digitsOnly.slice(0,3)}) ${digitsOnly.slice(3,6)}-${digitsOnly.slice(6,10)}`;
      }
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    
    // Add tracking event
    trackEvent('Lead', 'qual-first/step2')
      .catch(error => console.error('Error tracking SubmitApplication:', error));

    // Validate phone number
    if (!validatePhone(formData.phone)) {
      setError('Please enter a valid phone number');
      setIsSubmitting(false);
      return;
    }

    try {
      if (!existingCaseId) {
        throw new Error('No case ID provided');
      }

      // Always update the existing case
      await api.updateCase(existingCaseId, {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        status: 'pending'
      });

      onComplete(formData);
    } catch (error) {
      console.error('Error saving personal info:', error);
      setError(error.message || 'Failed to save personal information. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
 
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white py-12">
      <div className="mx-auto max-w-7xl px-4">
        <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">Step 2: Get in Touch</h1>
        
        <div className="grid grid-cols-1 xl:grid-cols-[300px_1fr_300px] gap-8 mb-16">
          {/* Left Fact */}
          <div className="hidden xl:block">
            <div className="bg-white rounded-xl shadow-lg p-8 border border-gray-100">
              <div className="text-blue-500 mb-6">
                <Activity size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                7.4 Million
              </h3>
              <p className="text-gray-600 leading-relaxed">
                Misdiagnosis errors are made every year in US emergency rooms alone
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: US Dept. of Health and Human Services
              </p>
            </div>
          </div>

          {/* Main Form Container */}
          <div className="max-w-3xl mx-auto w-full">
            <div className="bg-white shadow-lg rounded-xl p-8">
              <div className="bg-gray-50 border border-gray-200 rounded-xl p-4 mb-8">
                <div className="flex items-center mb-2">
                  <svg className="h-5 w-5 mr-2 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span className="font-medium text-gray-900">What happens next?</span>
                </div>
                <p className="text-gray-700">
                  We'll review your case and get in touch to help you get the support you need. We're currently helping <span className="font-bold text-gray-900">{activeInvestigations}</span> patients get paid back for bad healthcare.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-gray-700 text-sm font-semibold mb-2">Full Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your full name"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email address"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                    <Phone className="mr-2 h-5 w-5 text-blue-500" />
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="(555) 555-5555"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  />
                </div>
                {error && (
                  <Alert variant="destructive">
                    <div className="flex items-center">
                      <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span className="font-semibold">Error</span>
                    </div>
                    <p className="mt-2">{error}</p>
                  </Alert>
                )}
                <button 
                  type="submit" 
                  className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-full transition duration-300 ease-in-out flex items-center justify-center"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Next Step'}
                  <svg className="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </form>
            </div>
          </div>

          {/* Right Fact */}
          <div className="hidden xl:block">
            <div className="bg-white rounded-xl shadow-lg p-8 border border-gray-100">
              <div className="text-green-500 mb-6">
                <DollarSign size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                $40+ Billion
              </h3>
              <p className="text-gray-600 leading-relaxed">
                In damages paid because of medical negligence every year
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: Health Resources and Services Administration
              </p>
            </div>
          </div>
        </div>

        {/* Shared bottom content */}
        <SharedBottomContent blogPosts={blogPosts} isFormStep={true} />
      </div>
    </div>
  );
};

export default Step2ContactInfo;
