import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { fetchBlogPost } from '../../services/api';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPost = async () => {
      try {
        const data = await fetchBlogPost(slug);
        setPost(data);
      } catch (err) {
        setError('Failed to load blog post');
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [slug]);

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (error) return <div className="text-center py-8 text-red-600">{error}</div>;
  if (!post) return <div className="text-center py-8">Post not found</div>;

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {post.image_url && (
        <img 
          src={post.image_url} 
          alt={post.title}
          className="w-full h-64 object-cover rounded-lg mb-8"
        />
      )}
      <h1 className="text-4xl font-bold text-gray-900 mb-4">{post.title}</h1>
      <div className="text-gray-500 mb-8">
        {new Date(post.published_at).toLocaleDateString()}
      </div>
      <div className="prose prose-lg max-w-none prose-headings:mt-12 prose-headings:mb-6 prose-p:my-6 prose-ul:my-6 prose-li:my-2">
        <ReactMarkdown
          breaks={true}
          components={{
            p: ({node, children, ...props}) => {
              // Check if the paragraph contains only a single line break
              if (children && children.length === 1 && children[0] === '\n') {
                return <div className="my-8" />;
              }
              return <p className="whitespace-pre-line mb-6" {...props}>{children}</p>;
            },
            h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-12 mb-6" {...props} />,
            h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-8 mb-4" {...props} />,
            ul: ({node, ...props}) => <ul className="list-disc pl-6 my-6 space-y-2" {...props} />,
            li: ({node, ...props}) => <li className="my-2" {...props} />,
            strong: ({node, ...props}) => <strong className="font-bold" {...props} />,
          }}
        >
          {post.content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default BlogPost; 