import React, { useState, useEffect } from "react";
import YouTubePlayer from './YouTubePlayer';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Clipboard, Activity, DollarSign } from "lucide-react";
import { patientFlowAPI, trackEvent, fetchBlogPosts } from '../../services/api';

const LandingPage = ({ session }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [blogPosts, setBlogPosts] = useState([]);

  const handleGetStarted = () => {
    // Preserve query parameters when navigating
    navigate(`/qual-first/step1${location.search}`);
  };

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const posts = await fetchBlogPosts();
        setBlogPosts(posts.slice(0, 3)); // Get first 3 posts
      } catch (error) {
        console.error('Error loading blog posts:', error);
      }
    };
    loadPosts();
  }, []);

  const facts = [
    {
      icon: <Clipboard className="text-red-500" size={36} />,
      number: "1.1 Million",
      description: "Patients in the US are disabled or die yearly due to diagnostic errors",
      source: "Source: 2024 BMJ Quality & Safety study"
    },
    {
      icon: <Activity className="text-blue-500" size={36} />,
      number: "7.4 Million",
      description: "Misdiagnosis errors are made every year in US emergency rooms alone",
      source: "Source: US Dept. of Health and Human Services"
    },
    {
      icon: <DollarSign className="text-green-500" size={36} />,
      number: "$40+ Billion",
      description: "In damages paid because of medical negligence every year",
      source: "Source: Health Resources and Services Administration"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        <div className="text-center mb-8">
          <h1 className="text-5xl font-bold mb-4 text-gray-800 leading-tight">
            Think Your Doctors 
            <br/>
            Made a Mistake?
          </h1>
          <p className="text-2xl text-gray-600 mb-4">
            Find out if you were <b>misdiagnosed</b> or didn't get the treatment you deserved.
            <br/> 
            Your first investigation is <b>free</b> until March 27th.
          </p>
        </div>

        <div className="text-center mb-12">
          <button
            onClick={handleGetStarted}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-4 px-10 rounded-full text-lg transition duration-300 ease-in-out shadow-md"
          >
            Find Out What Happened Now
          </button>
        </div>
        
        {/* Desktop Layout */}
        <div className="hidden md:flex md:flex-row md:gap-8 mb-12 justify-center">
          {/* Left fact */}
          <div className="w-1/4">
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col justify-center h-full border border-gray-100">
              <div className="text-red-500 mb-6">
                <Clipboard size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                1.1 Million
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Patients in the US are disabled or die yearly due to diagnostic errors
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: 2024 BMJ Quality & Safety study
              </p>
            </div>
          </div>
         
           
          {/* Video section */}
          <div className="w-2/4">
                <YouTubePlayer isMobile={false} />
          </div>

          {/* Right fact */}
          <div className="w-1/4">
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col justify-center h-full border border-gray-100">
              <div className="text-blue-500 mb-6">
                <Activity size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                7.4 Million
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Misdiagnosis errors are made every year in US emergency rooms alone
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: US Dept. of Health and Human Services
              </p>
            </div>
          </div>
        </div>

        {/* Mobile Video */}
        <div className="md:hidden mb-8">
          <YouTubePlayer isMobile={true} />
        </div>

        {/* Healthcare Systems Section */}
        <div className="mb-8 md:mb-16">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-8">
            We've Investigated Cases From Leading Hospitals and Clinics
          </h2>
          <div className="grid grid-cols-3 md:grid-cols-6 gap-4 md:gap-6 items-center justify-items-center">
            <a href="https://www.mountsinai.org/" target="_blank" rel="noopener noreferrer">
              <img src="/logos/mount-sinai.png" alt="Mount Sinai" className="h-10 md:h-12 w-auto grayscale hover:grayscale-0 transition-all" />
            </a>
            <a href="https://healthy.kaiserpermanente.org/" target="_blank" rel="noopener noreferrer">
              <img src="/logos/kaiser.png" alt="Kaiser Permanente" className="h-10 md:h-12 w-auto grayscale hover:grayscale-0 transition-all" />
            </a>
            <a href="https://hcahealthcare.com/" target="_blank" rel="noopener noreferrer">
              <img src="/logos/hca.png" alt="HCA Healthcare" className="h-10 md:h-12 w-auto grayscale hover:grayscale-0 transition-all" />
            </a>
            <a href="https://www.concentra.com/" target="_blank" rel="noopener noreferrer">
              <img src="/logos/concentra.png" alt="Concentra" className="h-10 md:h-12 w-auto grayscale hover:grayscale-0 transition-all" />
            </a>
            <a href="https://www.afcurgentcare.com/" target="_blank" rel="noopener noreferrer">
              <img src="/logos/afc.png" alt="American Family Care" className="h-10 md:h-12 w-auto grayscale hover:grayscale-0 transition-all" />
            </a>
            <a href="https://www.va.gov/health/" target="_blank" rel="noopener noreferrer">
              <img src="/logos/va.png" alt="Veterans Affairs" className="h-10 md:h-12 w-auto grayscale hover:grayscale-0 transition-all" />
            </a>
          </div>
        </div>

        {/* Mobile Facts - Moved here */}
        <div className="md:hidden space-y-4 mb-12">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
            You Might Be Owed Compensation
          </h2>
          {facts.map((fact, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg px-6 py-4 flex items-center justify-between border border-gray-100 flex-wrap gap-4">
              <div className="flex items-center flex-1 min-w-[280px]">
                <div className="mr-4">
                  {fact.icon}
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-800">
                    {fact.number}
                  </h3>
                  <p className="text-gray-600">
                    {fact.description}
                  </p>
                </div>
              </div>
              <p className="text-sm text-gray-500 w-full md:w-auto">
                {fact.source}
              </p>
            </div>
          ))}
        </div>

        {/* Blog Posts Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-8">
            Latest Patient Guidance
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {blogPosts.map((post) => (
              <Link 
                key={post.id} 
                to={`/blog/${post.slug}`}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
              >
                {post.image_url && (
                  <div className="aspect-w-16 aspect-h-9">
                    <img 
                      src={post.image_url} 
                      alt={post.title}
                      className="w-full h-48 object-cover"
                    />
                  </div>
                )}
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {post.title}
                  </h3>
                  <p className="text-gray-600 text-sm mb-4 line-clamp-3">
                    {post.excerpt}
                  </p>
                  <div className="text-sm text-gray-500">
                    {new Date(post.published_at).toLocaleDateString()}
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="text-center mt-8">
            <Link
              to="/guidance"
              className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium"
            >
              View All Patient Guidance
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </Link>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8 mb-12 max-w-4xl mx-auto border border-gray-100">
          <p className="text-lg text-gray-700 leading-relaxed">
            <span className="text-2xl font-semibold text-gray-800 block mb-4">About Us</span>
            <span className="block mb-3">
              We're patients, <a href="https://hls.harvard.edu/">advocates</a>, and <a href="https://www.roivant.com/">healthcare researchers</a> who wanted to make it easy for people to find out
              if something went wrong with their care and get peace of mind with a little effort as possible.
            </span>
            <span className="block mb-3">
              We believe the only way to fix healthcare is to give patients the tools to check their doctor's work and
              empower them to make things right as quickly, easily, and privately as possible.
            </span>
            <span className="block">
              Please get in touch if you are seeking clarity or confidence about your healthcare.
            </span>
          </p>
        </div>       
 
        <div className="text-center mb-6">
          <button
            onClick={handleGetStarted}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-4 px-10 rounded-full text-lg transition duration-300 ease-in-out shadow-md"
          >
            Find Out What Happened Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
