import React, { useState, useEffect } from 'react';
import { AlertTriangle, FileText, MapPin, Clock, Clipboard, Activity } from 'lucide-react';
import DateDropdownSelector from '../../../components/DateDropdownSelector';
import SharedBottomContent from '../../../components/SharedBottomContent';
import { fetchBlogPosts, trackEvent } from '../../../services/api';
import { useLocation } from 'react-router-dom';

const Alert = ({ children, variant = 'default' }) => {
  const bgColor = variant === 'destructive' ? 'bg-red-100' : 'bg-blue-100';
  const textColor = variant === 'destructive' ? 'text-red-800' : 'text-blue-800';
  
  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-md mb-4`}>
      {children}
    </div>
  );
};

const Step1CaseDetails = ({ existingCaseId, onComplete, existingData }) => {
  const location = useLocation();
  const [formData, setFormData] = useState(existingData || {
    condition: '',
    state: '',
    incident_date: '',
  });
  
  const [validationState, setValidationState] = useState({
    error: null,
    isSubmitting: false,
    touched: {
      condition: false,
      state: false,
      incident_date: false
    },
    showErrors: {
      condition: false,
      state: false,
      incident_date: false
    }
  });

  const [blogPosts, setBlogPosts] = useState([]);
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (existingData) {
      setFormData(existingData);
    }
  }, [existingData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const posts = await fetchBlogPosts();
        setBlogPosts(posts.slice(0, 3));
      } catch (error) {
        console.error('Error loading blog posts:', error);
      }
    };
    loadPosts();
  }, []);

  useEffect(() => {
    // Parse query parameters when component mounts
    const params = new URLSearchParams(location.search);
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
  }, [location.search]);

  const validateField = (name, value) => {
    switch (name) {
      case 'condition':
        return value.length >= 10 ? '' : 'Please provide more detail about your condition';
      case 'state':
        return value.length >= 2 ? '' : 'Please enter a valid location';
      case 'incident_date':
        return value ? '' : 'Please select when this occurred';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const name = e.target.name === 'incidentDate' ? 'incident_date' : e.target.name;
    const value = e.target.value;

    setFormData(prev => ({ ...prev, [name]: value }));
    
    setValidationState(prev => ({
      ...prev,
      touched: { ...prev.touched, [name]: true }
    }));
  };

  const handleBlur = (e) => {
    const name = e.target.name === 'incidentDate' ? 'incident_date' : e.target.name;
    
    setValidationState(prev => ({
      ...prev,
      showErrors: { ...prev.showErrors, [name]: true },
      error: validateField(name, formData[name])
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationState(prev => ({ 
      ...prev, 
      isSubmitting: true, 
      error: null,
      showErrors: {
        condition: true,
        state: true,
        incident_date: true
      }
    }));

    // Add tracking event
    trackEvent('Qual_Info', 'qual-first/step1')
      .catch(error => console.error('Error tracking Lead:', error));

    // Validate all fields
    const errors = Object.keys(formData).map(key => validateField(key, formData[key])).filter(Boolean);
    
    if (errors.length > 0) {
      setValidationState(prev => ({
        ...prev,
        isSubmitting: false,
        error: errors[0],
        touched: Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: true }), {})
      }));
      return;
    }

    try {
      // Pass both formData and queryParams to onComplete
      await onComplete(formData, queryParams);
    } catch (error) {
      setValidationState(prev => ({
        ...prev,
        error: error.message || 'Failed to save case details. Please try again.'
      }));
    } finally {
      setValidationState(prev => ({ ...prev, isSubmitting: false }));
    }
  };

  const shouldShowError = (fieldName) => {
    return validationState.showErrors[fieldName] && validateField(fieldName, formData[fieldName]);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white py-12">
      <div className="mx-auto max-w-7xl px-4">
        <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">Check Your Case</h1>
        
        <div className="grid grid-cols-1 xl:grid-cols-[300px_1fr_300px] gap-8 mb-8">
          {/* Left Fact */}
          <div className="hidden xl:block">
            <div className="bg-white rounded-xl shadow-lg p-8 border border-gray-100">
              <div className="text-red-500 mb-6">
                <Clipboard size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                1.1 Million
              </h3>
              <p className="text-gray-600 leading-relaxed">
                Patients in the US are disabled or die yearly due to diagnostic errors
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: 2024 BMJ Quality & Safety study
              </p>
            </div>
          </div>

          {/* Main Form Container */}
          <div className="max-w-3xl mx-auto w-full">
            <div className="bg-white shadow-lg rounded-xl p-8 mb-8">
              {/* Mobile Fact Bubble */}
              <div className="xl:hidden mb-8">
                <div className="bg-blue-50 rounded-xl p-4">
                  <div className="flex items-center mb-2">
                    <svg className="h-5 w-5 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="font-semibold text-blue-900">Did you know?</span>
                  </div>
                  <p className="text-blue-900">
                    1 in 20 patients are misdiagnosed every year in the US.
                  </p>
                  <p className="text-blue-600 text-xs mt-1">
                    Source: BMJ Quality & Safety
                  </p>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="condition" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                    <FileText className="mr-2 h-5 w-5 text-blue-500" />
                    What do you think went wrong with your healthcare?
                  </label>
                  <textarea
                    id="condition"
                    name="condition"
                    value={formData.condition}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    rows="4"
                    placeholder="Were you misdiagnosed or denied treatment? How did it affect you?"
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200
                      ${shouldShowError('condition') ? 'border-red-500' : 'border-gray-300'}`}
                  />
                  {shouldShowError('condition') && (
                    <p className="mt-1 text-sm text-red-600">{validateField('condition', formData.condition)}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="state" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                    <MapPin className="mr-2 h-5 w-5 text-blue-500" />
                    Where did it happen?
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    placeholder="Enter the city and state"
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200
                      ${shouldShowError('state') ? 'border-red-500' : 'border-gray-300'}`}
                  />
                  {shouldShowError('state') && (
                    <p className="mt-1 text-sm text-red-600">{validateField('state', formData.state)}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="incident_date" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                    <Clock className="mr-2 h-5 w-5 text-blue-500" />
                    When did this happen?
                  </label>
                  <DateDropdownSelector
                    formData={formData}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                {validationState.error && (
                  <Alert variant="destructive">
                    <div className="flex items-center">
                      <AlertTriangle className="h-5 w-5 mr-2" />
                      <span className="font-semibold">Error</span>
                    </div>
                    <p className="mt-2">{validationState.error}</p>
                  </Alert>
                )}

                <button 
                  type="submit" 
                  className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-full transition duration-300 flex items-center justify-center"
                  disabled={validationState.isSubmitting}
                >
                  {validationState.isSubmitting ? 'Submitting...' : 'Next'}
                  <svg className="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </form>
              <div className="text-center text-gray-600 text-sm flex items-center justify-center mt-6">
              <svg className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
              Your information is secure and will only be used for case review purposes.
            </div>
            </div>
          </div>

          {/* Right Fact */}
          <div className="hidden xl:block">
            <div className="bg-white rounded-xl shadow-lg p-8 border border-gray-100">
              <div className="text-blue-500 mb-6">
                <Activity size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                7.4 Million
              </h3>
              <p className="text-gray-600 leading-relaxed">
                Misdiagnosis errors are made every year in US emergency rooms alone
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: US Dept. of Health and Human Services
              </p>
            </div>
          </div>
        </div>

        {/* Shared bottom content */}
        <SharedBottomContent blogPosts={blogPosts} isFormStep={true} />
      </div>
    </div>
  );
};

export default Step1CaseDetails;
